import React from "react";
import { graphql } from "gatsby";
import Page from "../../../components/page";
import AccountSettings from "../../../containers/user/containers/AccountSettings";
import ProtectedRoute from "../../../containers/user/components/ProtectedRoute";

const UserAccountPage = ({ data }: { data: Queries.MyAccountQueryDataQuery }) => {
  return (
    <Page {...data.datoCmsWebPage} container="fluid">
      <ProtectedRoute component={AccountSettings} />
    </Page>
  );
};

export const query = graphql`
  query MyAccountQueryData($locale: String!) {
    datoCmsWebPage(path: { eq: "user/account" }, locale: { eq: $locale }) {
      ...WebPageFragment
    }
  }
`;

export default UserAccountPage;
