import React from "react";

export default function VerifiedIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.9114 6.63993L10.267 5.99993L14.0003 2.79993L17.7336 5.99993L22.0892 6.63993L22.7114 11.1199L25.2003 14.3199L22.7114 17.5199L22.0892 21.9999L17.7336 22.6399L14.0003 25.1999L10.267 22.6399L5.9114 21.9999L5.28918 17.5199L2.80029 14.3199L5.28918 11.1199L5.9114 6.63993ZM18.9975 11.8833C19.3749 11.443 19.3239 10.7801 18.8836 10.4027C18.4433 10.0253 17.7805 10.0763 17.4031 10.5166L13.3306 15.2678L10.5322 12.5471C10.1164 12.1428 9.45168 12.1522 9.04745 12.568C8.64321 12.9838 8.65258 13.6485 9.06836 14.0528L12.6684 17.5528C12.8762 17.7549 13.1584 17.862 13.448 17.8488C13.7376 17.8357 14.0088 17.7034 14.1975 17.4833L18.9975 11.8833Z"
        fill="#FFBB00"
      />
    </svg>
  );
}
