import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default function EmailIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
      style={{ fill: "none", ...(props.style || {}) }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 6C2.5 5.17157 3.17157 4.5 4 4.5H20C20.8284 4.5 21.5 5.17157 21.5 6V6.98899V7.00967V16.9826C21.5003 16.9946 21.5003 17.0067 21.5 17.0189V18C21.5 18.8284 20.8284 19.5 20 19.5H4C3.17157 19.5 2.5 18.8284 2.5 18V6ZM20 15.6392L16.4006 13.3659C16.0504 13.1447 15.5872 13.2493 15.366 13.5995C15.1448 13.9497 15.2494 14.4129 15.5996 14.6341L20 17.4133V18H18.5H5.5H4V17.4135L8.40062 14.6341C8.75083 14.4129 8.85543 13.9497 8.63424 13.5995C8.41305 13.2493 7.94984 13.1447 7.59963 13.3659L4 15.6393V8.42359L11.576 13.6185C11.8316 13.7938 12.1687 13.7938 12.4243 13.6185L20 8.42369V15.6392ZM20 6V6.6049L12.0001 12.0906L4 6.60482V6H5.5H18.5H20Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
