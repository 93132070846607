import { Box, Typography } from "@material-ui/core";
import React from "react";

export type AccountMenuGroupProps = {
  children: JSX.Element | JSX.Element[];
  title: string;
};

const AccountMenuGroup = ({ children, title }: AccountMenuGroupProps) => (
  <Box width="100%" pb={0.5} mt={0} mb={{ xs: 4, md: 5 }}>
    <Typography variant="h5">{title}</Typography>
    <Box pt={{ xs: 2, md: 3 }}>{children}</Box>
  </Box>
);

export default AccountMenuGroup;
