import { Box, Typography, useTheme } from "@material-ui/core";
import React from "react";
import useTranslate from "../../../../../hooks/use-translate";
import VerifiedIcon from "./VerifiedIcon";

type Props = {
  startIcon: React.ReactNode;
  label: string;
  verified?: boolean;
  borderBottom?: boolean;
};

export default function AccountItemEmailPhone({
  label,
  verified,
  startIcon,
  borderBottom
}: Props) {
  const theme = useTheme();
  const t = useTranslate();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderBottom={
        borderBottom ? `1px solid ${theme.palette.grey[300]}` : undefined
      }
      px={1.75}
      minHeight="50px"
    >
      <Box display="flex" alignItems="center">
        {startIcon}

        <Typography
          variant="body2"
          style={{
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(0.5),
            wordBreak: "break-all"
          }}
        >
          {label}
        </Typography>
      </Box>

      {verified !== undefined && (
        <>
          {verified ? (
            <VerifiedIcon />
          ) : (
            <Typography
              className="compact"
              color="textSecondary"
              style={{ flexShrink: 0 }}
            >
              {t("accountSettings.notVerified")}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
}
