import { Box, Button } from "@material-ui/core";
import React from "react";
import EmailIcon from "../../../../components/icons/email";
import SmsIcon from "../../../../components/icons/sms";
import { prettifyPhoneNumber } from "../../../../helpers/phone-number";
import { useAuthContextSelector } from "../../../../hooks/use-auth";
import useTranslate from "../../../../hooks/use-translate";
import UserAccountLayout from "../../components/UserAccountLayout";
import AccountButton from "./components/AccountButton";
import AccountMenuItemEmailPhone from "./components/AccountMenuItemEmailPhone";
import AccountMenuGroup from "./components/AccountMenuGroup";
import LocalizedLink from "../../../../components/localized-link";
import useFeatureFlag from "../../../../hooks/use-feature-flag";
import useLocalizedLocationPath from "../../../../hooks/use-localized-location-path";

export default function UserAccount() {
  const t = useTranslate();
  const getLocalizedPath = useLocalizedLocationPath();
  const user = useAuthContextSelector(c => c.user);
  const logout = useAuthContextSelector(c => c.logout);

  // User logout
  const handleLogoutClick = () => {
    logout({
      redirectPath: getLocalizedPath("/")
    });
  };

  const isUnlockPodLinksEnabled = useFeatureFlag("UNLOCK_APP_LINKS");
  const isXpressEnabled = useFeatureFlag("SHOP_XPRESS");
  const isMyPodsEnabled = useFeatureFlag("MY_PODS");

  return (
    <UserAccountLayout
      title={t("accountSettings.title")}
      containerWidth="wide"
      showLoading={!user}
    >
      {/* Express Delivery */}
      {isXpressEnabled && (
        <AccountMenuGroup title={t("accountSettings.menu.delivery")}>
          <AccountButton to="/user/orders">
            {t("accountSettings.myorders")}
          </AccountButton>
        </AccountMenuGroup>
      )}

      {/* Unlock */}
      {isUnlockPodLinksEnabled && (
        <AccountMenuGroup title="Unlock Pod">
          <AccountButton to="/unlock">
            {t("accountSettings.menu.unlockPod")}
          </AccountButton>
          <AccountButton to="/user/pods">
            {t("accountSettings.menu.previousUnlocked")}
          </AccountButton>
        </AccountMenuGroup>
      )}
      {!isUnlockPodLinksEnabled && isMyPodsEnabled && (
        <AccountMenuGroup title="My Pods">
          <AccountButton to="/user/pods">
            {t("accountSettings.menu.previousSaved")}
          </AccountButton>
        </AccountMenuGroup>
      )}

      {/* Support */}
      <AccountMenuGroup title={t("accountSettings.menu.help")}>
        <AccountButton to="/support">
          {t("accountSettings.menu.support")}
        </AccountButton>
      </AccountMenuGroup>

      {/* Account */}
      <AccountMenuGroup title={t("accountSettings.menu.account")}>
        <Box
          boxShadow="0px 3px 5px rgba(0, 0, 0, 0.05)"
          borderRadius="3px"
          bgcolor="grey.100"
          display="flex"
          flexDirection="column"
          alignItems="stretch"
          mb={2}
        >
          {/* User phone number */}
          <AccountMenuItemEmailPhone
            label={
              user?.phoneNumber
                ? prettifyPhoneNumber(user.phoneNumber)
                : t("accountSettings.noPhoneNumber")
            }
            startIcon={<SmsIcon />}
            verified={user?.phoneNumber ? user.phoneVerified : undefined}
            borderBottom
          />
          {/* User email */}
          <AccountMenuItemEmailPhone
            label={user?.email || t("accountSettings.noEmail")}
            startIcon={<EmailIcon />}
            verified={user?.email ? user.emailVerified : undefined}
          />
        </Box>

        {/* Verify phone number */}
        {user?.phoneNumber && !user?.phoneVerified && (
          <Box mb={2}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              component={LocalizedLink}
              to="/user/account/phone-number"
            >
              {t("accountSettings.verifyPhoneNumber")}
            </Button>
          </Box>
        )}

        {/* Verify email */}
        {user?.email && !user?.emailVerified && (
          <Box mb={3}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              component={LocalizedLink}
              to="/user/account/email"
            >
              {t("accountSettings.verifyEmail")}
            </Button>
          </Box>
        )}

        {/* Add/Update phone number */}
        <AccountButton to="/user/account/phone-number">
          {t(
            user?.phoneNumber
              ? "accountSettings.changePhoneNumber"
              : "accountSettings.addPhoneNumber"
          )}
        </AccountButton>

        {/* Add/Update email */}
        <AccountButton to="/user/account/email">
          {t(
            user?.email
              ? "accountSettings.changeEmail"
              : "accountSettings.addEmail"
          )}
        </AccountButton>

        {/* Sign out */}
        <Box mt={3}>
          <AccountButton onClick={handleLogoutClick}>
            {t("accountSettings.signout")}
          </AccountButton>
        </Box>
      </AccountMenuGroup>
    </UserAccountLayout>
  );
}
