import { Button, styled } from "@material-ui/core";
import React from "react";
import ChevronRight from "../../../../../components/icons/chevron-right";
import LocalizedLink from "../../../../../components/localized-link";

const CustomButton = styled(props => (
  <Button
    {...props}
    variant="contained"
    component={props.to ? LocalizedLink : undefined}
  />
))(({ theme }) => ({
  textAlign: "left",
  display: "flex",
  justifyContent: "space-between",
  borderRadius: "3px",
  backgroundColor: theme.palette.common.white,
  fontSize: "14px !important",
  lineHeight: "16px !important",
  fontWeight: 600,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1.75),
  paddingRight: theme.spacing(1.75),
  boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.05) !important",
  marginBottom: theme.spacing(1),
  minHeight: "40px",
  [theme.breakpoints.up(`md`)]: {
    marginBottom: theme.spacing(2)
  },
  "&:last-child": {
    marginBottom: 0
  }
}));

const AccountButton = (props: {
  to?: string;
  href?: string;
  onClick?: () => void;
  children: React.ReactDOM | string;
}) => {
  return (
    <CustomButton fullWidth endIcon={<ChevronRight />} {...props}>
      {props.children}
    </CustomButton>
  );
};
export default AccountButton;
